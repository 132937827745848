import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Office from "../components/office";
import {motion} from "framer-motion";
import SentIcon from '../images/sent.inline.svg';
import { navigate } from 'gatsby';

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const ContactPage = () => {
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }
    return (
        <Layout>
            <SEO title={`Contact us`} />

            <div className="o-container p-contact">
                <h1 className="u-h1 u-c o-heading">Let's <strong>get digital</strong></h1>
                <p className={"u-l"}>Looking forward to meeting you.</p>

                <div className=" p-contact__layout">
                    <form className={"o-form [ p-contact__form "}
                          name="contact"
                          method="POST"
                          data-netlify="true"
                          onSubmit={handleSubmit}
                          action="/contact/thanks/"
                    >
                        <div className="o-form__item">
                            <input type={"text"} required={true} name={"name"} placeholder={"Your name"} onChange={handleChange} />
                        </div>
                        <div className="o-form__item">
                            <input type={"text"} required={true} name={"contact"} placeholder={"How can we reach you"} onChange={handleChange} />
                        </div>
                        <div className="o-form__item">
                            <textarea placeholder={"Small description of your project"} required={true} name={"message"} onChange={handleChange} />
                        </div>
                        <motion.button title={"Submit contact form"} type={"submit"} className={"c-button c-button--rounded"}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                        ><SentIcon /></motion.button>

                        <input type="hidden" name="form-name" value="contact" />

                    </form>

                    <div className={"[ p-contact__info ]"}>
                        <h2 className={"u-h6"}>Direct contact</h2>
                        David Candreva<br />
                        <a href={"tel:+32494544484"}>+32 494 54 44 84</a><br />
                        <a href={"mailto:david@theappoffice.be"}>david@theappoffice.be</a><br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <Office/>

        </Layout>
    );
};

export default ContactPage;
